import { defineComponent } from "vue";

import KeySector from "@/components/key-sector/index.vue";

import agriculture from "../../assets/images/agriculture.png";
import mining from "../../assets/images/mining.png";
import transportation from "../../assets/images/transportation.png";
import infrastructure from "../../assets/images/infrastructure.png";
import healthcare from "../../assets/images/healthcare.png";
import energy from "../../assets/images/energy.png";

export default defineComponent({
  name: "Sector",
  components: { KeySector },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  setup() {
    const updatePageTitle = (name) => {
      document.title = `${name} | NASIDA Key Sectors`;
    };

    return { loaded: false, updatePageTitle };
  },
  computed: {
    bodyParagraphs() {
      switch (this.name) {
        case "agriculture":
          this.updatePageTitle("Agriculture");
          return [
            "With its wide diversity of agro-ecological zones, Nasarawa state is driving agricultural development through novel partnerships that will drive growth and enable sustainable and efficient production of agricultural commodities. Agriculture, therefore, is the mainstay of the economy of the State with over 70% of the population involved in subsistence farming.",
            "The state is known for its organized commodity aggregation system which ensures the marketability and traceability of its produce. Due to the ever-growing demand for agricultural produce and the rising global population, Nasarawa has developed partnerships with key players within the agribusiness sector to ensure that it plays a vital role in the agricultural economy Our PPP framework, provides an opportunity to partner with local and international famers and aggregators to commercialize key opportunities within the agricultural value chain.",
            "The Nasarawa state government is focused on the production and processing of commodities such as, sesame, rice, ginger amongst others, within the global agricultural value chain. By leveraging on opportunities in aquaculture, forestry, and livestock farming, the state is strategically positioned to support accelerated growth in light of the increased challenges climate change presents. ",
          ];
        case "mining":
          this.updatePageTitle("Mining");
          return [
            "Nasarawa state is richly endowed with a variety of solid minerals of various categories ranging from precious metals, gemstones and industrial minerals such as barites, gypsum, kaolin and marble, gold, columbite.",
            "NASIDA, through its thematic holding company, Minco, is positioning Nasarawa state as a sectoral heavy weight within the fast-growing mineral resource markets while supporting continued growth in domestic manufacturing and the creation of high-skilled jobs.",
            "Our goal is to leverage on the global demand for metals, minerals and gemstones located in the state, and to drive investment to the down-stream mining sector, minerals processing and aggregation.",
          ];
        case "healthcare":
          this.updatePageTitle("Healthcare");
          return [
            "NASIDA is focused on addressing the state’s most pressing healthcare needs through the development of a thriving healthcare sector. In order to enhance the capabilities of the sector, we are supporting the development of a world-class hospitals, maternity centers and vaccines production plant.",
            "The state is strategically positioned to build a commercially sustainable private healthcare sector that caters to its growing population.",
          ];
        case "transportation":
          this.updatePageTitle("Transportation");
          return [
            "The State’s proximity to the federal capital territory and the development of low-income housing clusters, has resulted in mass migration of workers.  NASIDA shall leverage on the opportunity mass migration offers to introduce a mass transportation system for goods and persons within the state.",
          ];
        case "energy":
          this.updatePageTitle("Energy");
          return [
            "We are focused on the development, commercialization and deployment of energy solutions that can electrify a large portion of the state area. Like other states in Nigeria, Nasarawa state experiences constraints in the generation of electricity which is as a result of infrastructure constraints across the entire value chain. NASIDA, through its partners is advancing the provision of alternative, efficient and climate conscious sources of energy within the State. Through the adoption of mini-grid technology, ourselves and our partners have provided electricity to several communities within the state.",
          ];
        case "infrastructure":
          this.updatePageTitle("Infrastructure");
          return [
            "NASIDA is focused on creating the physical systems that are necessary to run a modern, industrialized State. Infrastructure investment plays a key role in driving economic development, transformation and growth, rapid industrialisation which ultimately leads to creation of jobs. Nasarawa state has an active and increasing infrastructure project portfolio that it expects to execute through Public-Private Partnership (PPP) arrangements over the next 10 years. ",
          ];
        default:
          return [];
      }
    },
    highlights() {
      switch (this.name) {
        case "agriculture":
          return [
            {
              title: "Special Agro- Processing Zone (SAPz)",
              body:
                "The Special Agro-Industrial Processing Zone (SAPz) is an integrated development initiative designed to concentrate Agro-processing activities within areas of high agricultural potential to boost productivity as well as integrate production, processing, and marketing of selected commodities.",
            },
            {
              title: "Nasarawa Commodities Company",
              body:
                "The Nasarawa State Government has setup an agricultural commodity company to complement the Federal Government on import substitution strategy & to support investment across the value chain",
            },
            {
              title: "National Livestock Transformation Plan",
              body:
                "Nasarawa State Government has launched a pilot in Awe Local Governemt Area and seeks investors willing to partner and anchor its grazing reserves, to improve livestock value chain and encourage ranching across the State",
            },
          ];
        case "mining":
          return [
            {
              title: "Ugya Mining",
              body:
                "Ugya Mining Company is a co-creation of individual indigenous mining players and the Nasarawa State Government. Who in a bid to improve economies of scale, local participation and promote diversification of The State economy have unified to form a consortium to conduct the exploration, research and processing of marble within Ugya Community, Toto Local Government Area of the state.",
            },
            {
              title: "Gudi Industrial Hub",
              body:
                "Nasarawa State is seeking investors interested in a private sector led industrial establishments to be located along the rail line intended for manufacturing and processing of solid minerals and supporting industries.",
            },
          ];
        case "healthcare":
          return [
            {
              title: "Dalhatu Araf Specialist Hospital",
              body:
                "Nasarawa state government has concessioned the radiology department, mortuary and laboratory units of Dalhatu Araf Specialist hospital. Dalhatu Araf Specialist Hospital has been a key healthcare delivery centre for the state over the years. In a bid to achieve a more effective and efficient service delivery at the Dalhatu Araf Specialist Hospital in Lafia, Government decided to concession the operations and maintenance of three (3) key services within the hospital namely; Radiology, Laboratory, and Mortuary departments. The concession will see increased private sector investment in these departments, improve the quality of service, and support overall improvements in the delivery of quality health care.",
            },
            {
              title: "Vaccine Production Plant",
              body:
                "Nasarawa state government is collaborating with private sector to provide a sustainable source of high-quality products to address vaccine preventable disease.",
            },
          ];
        case "transportation":
          return [
            {
              title: "Nasarawa Transport Company",
              body:
                "The Nasarwa state government has built two bus terminals (Karu and Lafia) with a combined capacity of 1,200 parking spaces amongst other facilities within the terminals. The concession of both terminals to a private sector partner for a more efficient operation is at an advanced stage of negotiation.",
            },
            {
              title: "Bus Rapid Transport System",
              body:
                "The Nasarawa state government is eager to partner with investors in creating a more inclusive and organized transport system that caters to the evolving demographic of the State.",
            },
          ];
        case "energy":
          return [
            {
              title: "Minigrids Solar Systems",
              body:
                "Husk Power supported by the NASARAWA state government had newly launched a minigrid in Rukubi. The completion of six minigrids by Husk Power Systems in Nasarawa State is an important step in scaling rural electrification and achieving energy access for all Nigerians.",
            },
            {
              title: "Meter Assembly Plant",
              body:
                "The Nasarawa state government is establishing a Meter Assembly Plant to cover Nigeria and Africa's Metering Gap while ensuring the viability of the Nigeria Electricity Sector Industry.",
            },
          ];
        case "infrastructure":
          return [
            {
              title: "Gudi Industrial and Logistics Park",
              body:
                "The Gudi Industrial and Logistics Hub will be a Free Trade Zone, and it is structured to serve as critical industrial and logistic hub for the North Central Region of Nigeria. The centrality of Gudi, and its profile as a transit route to different part of the country, positions it in an advantageous position to serve multiple markets and industries.",
            },
            {
              title: "Loko Dry Port and Economic City",
              body:
                "To optimize the opportunities of this massive infrastructure development by the Federal Government, as well as the infrastructure vision of the State, Nasarawa State Government now intends to develop a Dry Port and trailer park, a light and heavy infrastructure park, and other supporting facility.",
            },
            {
              title: "Nasarawa Technology Village",
              body:
                "Nasarawa State Government recently entered a Joint Venture for the development of 67 hectares of land on the Abuja Corridors into a technology Village. The technology village has secured significant interest from major tech players, and the Family Homes Fund has also indicated willingness to develop an innovative and demonstration housing Estate within the Technology Village.",
            },
          ];
        default:
          return [];
      }
    },
    image() {
      switch (this.name) {
        case "agriculture":
          return agriculture;
        case "mining":
          return mining;
        case "healthcare":
          return healthcare;
        case "transportation":
          return transportation;
        case "energy":
          return energy;
        case "infrastructure":
          return infrastructure;
        default:
          return [];
      }
    },
  },
});
