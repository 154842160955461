<template>
  <div id="sector">
    <div class="text-3xl md:text-6xl font-semibold capitalize text-nasida-green">{{name}}</div>
    <img
      class="my-10 md:my-20"
      :src="image"
      alt=""
    />
    <div class="grid grid-cols-1 lg:grid-cols-4 xl:gap-x-10 md:mt-10">
      <div class="grid lg:hidden grid-cols-1 md:grid-cols-3 gap-5 mb-6">
        <KeySector
          name="agriculture"
          link="/sector/agriculture"
        />
        <KeySector
          name="mining"
          link="/sector/mining"
        />
        <KeySector
          name="healthcare"
          link="/sector/healthcare"
        />
        <KeySector
          name="transportation"
          link="/sector/transportation"
        />
        <KeySector
          name="energy"
          link="/sector/energy"
        />
        <KeySector
          name="infrastructure"
          link="/sector/infrastructure"
        />
      </div>
      <div class="col-span-3 text-justify gap-y-5 w-full lg:w-11/12 xl:w-full">
        <div v-if="bodyParagraphs.length >= 1">
          <p
            class="mb-6"
            v-for="paragraph in bodyParagraphs"
            :key="paragraph"
          >{{paragraph}}</p>
        </div>
        <div v-if="highlights.length >= 1">
          <div class="text-nasida-green text-left text-2xl md:text-4xl xl:text-5xl mb-8">
            <b class="capitalize">{{name}}</b> Porfolio Highlights
          </div>
          <div
            class="my-6"
            v-for="highlight in highlights"
            :key="highlight"
          >
            <p class="font-bold text-lg highlight-title">{{highlight.title}}</p>
            <p v-if="highlight">{{highlight.body}}</p>
          </div>
        </div>
      </div>
      <div class="hidden lg:grid col-span-1 gap-y-6">
        <KeySector
          name="agriculture"
          link="/sector/agriculture"
        />
        <KeySector
          name="mining"
          link="/sector/mining"
        />
        <KeySector
          name="healthcare"
          link="/sector/healthcare"
        />
        <KeySector
          name="transportation"
          link="/sector/transportation"
        />
        <KeySector
          name="energy"
          link="/sector/energy"
        />
        <KeySector
          name="infrastructure"
          link="/sector/infrastructure"
        />

      </div>
    </div>
  </div>
</template>

<script src="./sector.page.js"></script>
<style src="./sector.page.css" scoped lang="css"></style>